import { list as customerList } from "~/lib/api/customers";
import { keepPreviousData } from "@tanstack/vue-query";
import type { Customer } from "~/lib/api/types/customers";

export const useCustomerSearchStore = defineStore("customerSearchStore", () => {
    const searchPageVisited = ref<boolean>(false);
    const disableSearchWatcher = ref<boolean>(false);

    const searchString = ref<string>();
    const currentCursor = ref<string>();
    const { isLoading, isFetching, isError, data, error } = useQuery({
        queryKey: ["merchantApi.customers.list", currentCursor, searchString],
        queryFn: () =>
            customerList({
                cursor: currentCursor.value,
                per_page: 12,
                search: searchString.value,
            }),
        placeholderData: keepPreviousData,
    });

    const nextCursor = computed(() => data.value?.meta.next_cursor);
    const prevCursor = computed(() => data.value?.meta.prev_cursor);
    const hasNextPage = computed(
        () => typeof data.value?.meta.next_cursor === "string",
    );
    const hasPreviousPage = computed(
        () => typeof data.value?.meta.prev_cursor === "string",
    );
    function goToNextPage(): void {
        currentCursor.value = nextCursor.value ?? undefined;
    }
    function goToPreviousPage(): void {
        currentCursor.value = prevCursor.value ?? undefined;
    }

    function changeSearch(search?: string) {
        currentCursor.value = undefined;
        searchString.value = search ?? "";
    }

    return {
        searchPageVisited,
        customers: computed<Customer[] | undefined>(() =>
            data.value?.data.map(
                (c: Customer): Customer => ({
                    ...c,
                    external_identifier: c.external_identifier ?? "-",
                }),
            ),
        ),
        isLoading,
        isFetching,
        isError,
        error,
        changeSearch,
        hasNextPage,
        hasPreviousPage,
        goToNextPage,
        goToPreviousPage,
        currentCursor,
        searchString,
        disableSearchWatcher,
    };
});
